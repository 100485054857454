import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { api_constants } from '@constants/api-constants';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }

  getData(id): Observable<any>{
    let _params = {
      params: {
        id: id
      }
    }
    return this.http.get(api_constants.home.getData, _params);
  }

  postData(data): Observable<any>{
    return this.http.post(api_constants.home.saveData, data, { responseType: 'blob'});
  }
}
