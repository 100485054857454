<header class="bd-header bg-dark py-4 d-flex align-items-stretch border-bottom border-dark">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <h1 class="mt-2">Diagnóstico Bienestar Financiero</h1>
            </div>
            <div class="col-md-4">
                <img class="img-fluid" src="/assets/img/logo_invested_blanco.png">
            </div>
        </div>
    </div>
</header>
<div class="container">
    <div class="row">
        <div class="col-md-10 mx-auto mt-5 text-center">
            <h2>Esta herramienta te permitirá conocer el impacto de la falta de bienestar financiero en tu empresa.</h2>
            <p>Llena los datos de la calculadora para poder obtener un diagnóstico, el cual podrás descargar al final de esta página junto con un reporte completo.</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-1 mt-5 mb-3">
            <span class="step-instructions">1</span>
        </div>
        <div class="col-md-6 mt-5">
            <h2 class="text-start">Ingresa el número de colaboradores que tiene tu empresa:</h2>
        </div>
        <div class="col-md-3 mt-5">
            <input type="text" class="form-control form-control-lg" id="formGroupExampleInput" mask="separator.0"
                   thousandSeparator="," [allowNegativeNumbers]="false" (keyup)="setTotoalPersonal()"
                   [(ngModel)]="totalColaboradores" style="margin-top: -12px"
                   data-bs-toggle="tooltip" data-bs-placement="top" title="Escribe aquí el número total de los colaboradores de tu empresa"
                   (focus)='onFocus()'
                   (blur)='blur()'>
            <div id="emailHelp" class="form-text">Escribe aquí el número total de los colaboradores de tu empresa.</div>
        </div>
        <div class="col-md-2 mt-5">
            <h2>colaboradores</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-2 mt-5 mb-3">
                    <span class="step-instructions">2</span>
                </div>
                <div class="col-md-8 mt-5">
                    <h2 class="text-start">Ingresa el porcentaje o número de colaboradores:</h2>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-6">
                </div>
                <div class="col-md-3">
                    <p class="fs-6 text-center">Porcentaje de colaboradores</p>
                </div>
                <div class="col-md-3">
                    <p class="fs-6 text-center">Número de colaboradores</p>
                </div>
            </div>
            <div class="row mt-0">
                <div class="col-md-6 table-employee">
                    <p class="mt-3">{{categoryOne.ingreso}}</p>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control form-control-sm form-calc" id="formGroupExampleInput"
                           suffix="%" mask="999.00" (keyup)="getPersonalNumber(categoryOne)"
                           [allowNegativeNumbers]="false" [(ngModel)]="categoryOne.porcentajeRango">
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control form-control-sm form-calc" id="formGroupExampleInput"
                           (keyup)="getPercentNumber(categoryOne)" mask="separator.0" thousandSeparator=","
                           [allowNegativeNumbers]="false" [(ngModel)]="categoryOne.numeroRango">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 table-employee">
                    <p class="mt-3">{{categoryTwo.ingreso}}</p>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control form-control-sm form-calc" id="formGroupExampleInput"
                           suffix="%" mask="999.00" (keyup)="getPersonalNumber(categoryTwo)"
                           [allowNegativeNumbers]="false" [(ngModel)]="categoryTwo.porcentajeRango">
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control form-control-sm form-calc" id="formGroupExampleInput"
                           (keyup)="getPercentNumber(categoryTwo)" mask="separator.0" thousandSeparator=","
                           [allowNegativeNumbers]="false" [(ngModel)]="categoryTwo.numeroRango">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 table-employee">
                    <p class="mt-3">{{categoryThree.ingreso}}</p>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control form-control-sm form-calc" id="formGroupExampleInput"
                           suffix="%" mask="999.00" (keyup)="getPersonalNumber(categoryThree)"
                           [allowNegativeNumbers]="false" [(ngModel)]="categoryThree.porcentajeRango">
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control form-control-sm form-calc" id="formGroupExampleInput"
                           (keyup)="getPercentNumber(categoryThree)" mask="separator.0" thousandSeparator=","
                           [allowNegativeNumbers]="false" [(ngModel)]="categoryThree.numeroRango">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 table-employee">
                    <p class="mt-3">{{categoryFour.ingreso}}</p>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control form-control-sm form-calc" id="formGroupExampleInput"
                           suffix="%" mask="999.00" (keyup)="getPersonalNumber(categoryFour)"
                           [allowNegativeNumbers]="false" [(ngModel)]="categoryFour.porcentajeRango">
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control form-control-sm form-calc" id="formGroupExampleInput"
                           (keyup)="getPercentNumber(categoryFour)" mask="separator.0" thousandSeparator=","
                           [allowNegativeNumbers]="false" [(ngModel)]="categoryFour.numeroRango">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 table-employee">
                    <p class="mt-3">{{categoryFive.ingreso}}</p>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control form-control-sm form-calc" id="formGroupExampleInput"
                           suffix="%" mask="999.00" (keyup)="getPersonalNumber(categoryFive)"
                           [allowNegativeNumbers]="false" [(ngModel)]="categoryFive.porcentajeRango">
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control form-control-sm form-calc" id="formGroupExampleInput"
                           (keyup)="getPercentNumber(categoryFive)" mask="separator.0" thousandSeparator=","
                           [allowNegativeNumbers]="false" [(ngModel)]="categoryFive.numeroRango">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 table-employee">
                    <p class="mb-0">Total</p>
                </div>
                <div class="col-md-3">
                    <p class="mb-0 text-end fs-2 result-yellow">{{totalPercent | percent:'2.0'}}</p>
                </div>
                <div class="col-md-3">
                    <p class="mb-0 text-end fs-2 result-yellow" [ngClass]="{'total-different': totalPersonal != totalColaboradores}">{{totalPersonal | number}}
                    </p>
                </div>
            </div>
            <span style="font-size: 12px">Ingreso bruto mensual</span>
            <div class="row mt-3">
                <div class="col-md-12 mx-auto text-center" *ngIf="totalPersonal != totalColaboradores">
                    <div class="alert alert-danger alert-dismissible" role="alert">
                        Distribuye el total de colaboradores según su ingreso
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 mt-5">
            <div class="row mt-5">
                <div class="col-md-12">
                </div>
            </div>
            <p class="text-center">Distribución actual de colaboradores en tu empresa por rango salarial.</p>
            <div id="chart-container" style="margin-top: -18px;">
                <plotly-plot [data]="chart.data" [layout]="chart.layout" [config]="config"></plotly-plot>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 mx-auto my-0" #screen>
            <div id="chart-container" style="margin-top: -18px;" *ngIf="showBubbleChart">
                <plotly-plot [data]="chart.data" [layout]="chart.layout" [config]="config"></plotly-plot>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 mx-auto" #screenBubble>
            <div style="display: block">
                <canvas baseChart style="height: 1000px !important;" *ngIf="showBubbleChart"
                    [data]="bubbleChartData"
                    [options]="bubbleChartOptions"
                    [legend]="true"
                    [type]="bubbleChartType"
                    [plugins]="barChartPlugins">
                </canvas>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <h2 class="mt-5">Los colaboradores pasan en promedio 10h 48m al mes preocupados por temas financieros durante su jornada laboral*.</h2>
        </div>
        <div class="col-md-6">
            <div class="productividad">
                <h2 class="text-center">Esto representa en tu empresa:</h2>
                <div class="row mt-2 mb-4">
                    <div class="col-md-12 text-center">
                        <h1 class="m-0">{{productivityLoss | currency: 'USD' : 'symbol' : '1.0-0'}}</h1>
                    </div>
                    <div class="col-md-12 text-center">
                        <h2 class="m-0">Pérdidas mensuales por productividad*</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="bg-grey mt-5">
    <div class="container">
        <div class="row">
            <div class="col-md-1 mt-5">
                <span class="step-instructions">3</span>
            </div>
            <div class="col-md-11 mt-5">
                <h2 class="">Descubre cuánto aumenta tu productividad al reducir las horas de estrés financiero.</h2>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-3 text-center">
                <p class="mt-5 mb-5">Disminución de horas <br>preocupados por sus finanzas</p>
                <p class="mt-5 mb-5 result-yellow fs-2">{{average}} hrs</p>
            </div>
            <div class="col-md-3 text-center">
                <!--<p class="mb-0">14</p>
                <p class="result-yellow fs-2">+</p>-->
                <p class="fs-2 result-blue">14 hrs</p>
                <input type="range" class="form-range" min="1" max="14" step="1" id="range-hours"
                       (ngModelChange)="getTotals();" [(ngModel)]="average">
                <!--<p class="mt-5 mb-0 result-yellow fs-2">-</p>
                <p class="mt-0">1</p>-->
                <p class="mt-5 mb-0 fs-2 result-blue">1 hrs</p>
            </div>

            <div class="col-md-6">
                <h2 class="result-blue mb-3 text-center">Incremento de <br> productividad mensual*: </h2>
                <h1 class="result-yellow my-4 text-center">{{monthlyProductivityIncrease | currency: 'USD' : 'symbol' : '1.0-0'}}</h1>
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6 p-2 mx-auto my-1 table-productivity-result">
                                <span>1 año</span><span>{{oneYearPV | currency: 'USD' : 'symbol' : '1.0-0'}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 p-2 mx-auto my-1 table-productivity-result">
                                <span>3 años</span><span>{{threeYearsPV | currency: 'USD' : 'symbol' : '1.0-0'}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 p-2 mx-auto my-1 table-productivity-result">
                                <span>5 años</span><span>{{fiveYearsPV | currency: 'USD' : 'symbol' : '1.0-0'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row mt-5">
        <div class="col-md-12">
            <h2 class="result-blue">Un vistazo a los números de tu empresa: </h2>

        </div>
    </div>
    <div class="row ">
        <h2 class="text-center mt-5 mb-5">Del total de tus {{totalColaboradores | number:0}} colaboradores*:</h2>
        <div class="col-md-4">
            <div class="ratio-light text-center">
                <h1>{{pwc | number:0}} </h1>
                <h2>
                    De tus colaboradores se encuentran estresados por temas financieros
                </h2>
            </div>
        </div>
        <div class="col-md-4">
            <div class="ratio-dark text-center">
                <h1>{{def6.total | number:0}}</h1>
                <br>
                <h2>
                    No conoce <br>sus prestaciones
                </h2>
                <br>
            </div>
        </div>
        <div class="col-md-4">
            <div class="ratio-light text-center">
                <h1>{{cptr | number:0}}</h1>
                <h2>
                    de tus colaboradores están buscando otro trabajo que mejore su situación financiera
                </h2>
            </div>
        </div>
    </div>
    <div class="row">
        <h2 class="text-center mt-5 mb-5">Otros datos sobre el Bienestar Financiero en México*</h2>
        <div class="col-md-1"></div>
        <div class="col-md-2">
            <div class="miniratio-light">
                <h1>{{def1.total | number}}</h1>
                <p>No sabe cómo gasta su dinero</p>
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="col-md-2">
            <div class="miniratio-dark">
                <h1>{{def4.total | number:0}}</h1>
                <p>No ahorra para su retiro</p>
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="col-md-2">
            <div class="miniratio-light">
                <h1>{{def5.total | number:0}}</h1>
                <p>No tiene reserva de emergencias</p>
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="col-md-2">
            <div class="miniratio-dark">
                <h1>{{def2.total | number:0}}</h1>
                <p>
                    De las personas no tienen objetivos definidos
                </p>
            </div>
        </div>
        <div class="col-md-2">
            <div class="col-md-12">
                <div class="miniratio-light">
                    <h1>{{def3.total | number:0}}</h1>
                    <p>Destina +50% ingresos al pago de deudas</p>
                </div>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>
    <div class="row mt-5">
        <div class="col-md-6 mx-auto">
            <p class="text-center">La implementación de un programa de bienestar financiero en tu empresa es clave para el correcto desarrollo de tus colaboradores.</p>
            <p class="text-center">Descarga los resultados de este simulador en un reporte completo y recíbelo en tu correo electrónico.</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 my-3">
            <div class="row">
                <div class="col-md-8 mx-auto">
                    <div class="d-grid gap-2">
                        <button class="btn btn-primary btn-lg rounded-pill" id="btn-guardar" type="button" data-bs-toggle="modal"
                                data-bs-target="#saveModal">Descargar reporte</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 my-3">
            <div class="row">
                <div class="col-md-8 mx-auto">
                    <div class="d-grid gap-2">
                        <button class="btn btn-primary btn-lg rounded-pill" id="btn-guardar" type="button" data-bs-toggle="modal"
                                data-bs-target="#modalCotiza">Hablar con un experto</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-md-12">
            <p class="fs-6 mb-0">* Basado en las estadísticas obtenidas de las siguientes fuentes y reportes:</p>
            <p class="fs-6 mb-0"> - Reporte de Bienestar Financiero México 2022 </p>
            <p class="fs-6 mb-0"> - PWC 2022 Employee Financial Wellness Survey</p>
            <p class="fs-6 mb-0"> - Ceridian Pulse of Talent Report</p>
        </div>
    </div>
</div>







<!-- Modal -->
<div class="modal fade" id="saveModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Enviar por correo</h5>
                <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formSave" novalidate>
                    <div class="col m-2">
                        <label>Nombre*</label>
                        <input type="text" class="form-control form-control-lg" id="nombre" name="nombre"
                            [ngClass]="{'is-invalid': formSave.controls['nombre'].status == 'INVALID' && formSave.controls['nombre'].touched}"
                            formControlName="nombre" autocomplete="off" required
                            autofocus="autofocus">
                        <div class="invalid-feedback"
                            *ngIf="formSave.controls['nombre'].status == 'INVALID' && formSave.controls['nombre'].touched">
                            Campo requerido</div>
                    </div>
                    <div class="col m-2">
                        <label>Número telefónico</label>
                        <input type="text" class="form-control form-control-lg" id="nombre" name="nombre"
                            formControlName="telefono" autocomplete="off"
                            mask="(00)-0000-0000"
                            autofocus="autofocus">
                        <!-- <div class="invalid-feedback"
                            ng-show="SIGNUP.formUserSignup.nombre.$error.required && SIGNUP.formUserSignup.nombre.$touched">
                            Campo
                            requerido</div>
                        <div class="invalid-feedback"
                            ng-show="SIGNUP.formUserSignup.nombre.$error.pattern && SIGNUP.formUserSignup.nombre.$touched">
                            Escribe
                            solo letras</div> -->
                    </div>
                    <div class="col m-2">
                        <label>Correo empresarial*</label>
                        <input type="email" class="form-control form-control-lg"
                            [ngClass]="{'is-invalid': formSave.controls['mail'].status == 'INVALID' && formSave.controls['mail'].touched}"
                            id="coreo" name="coreo"
                            formControlName="mail" autocomplete="off" required
                            autofocus="autofocus">
                        <div class="invalid-feedback"
                            *ngIf="formSave.controls['mail'].status == 'INVALID' && formSave.controls['mail'].touched">
                            Introduce un correo empresarial válido</div>
                    </div>
                    <!-- <div class="col m-2">
                        <label>Empresa</label>
                        <input type="text" class="form-control form-control-lg" id="empresa" name="empresa"
                            formControlName="empresa" autocomplete="off"
                            autofocus="autofocus">
                        <div class="invalid-feedback"
                            ng-show="SIGNUP.formUserSignup.nombre.$error.required && SIGNUP.formUserSignup.nombre.$touched">
                            Campo
                            requerido</div>
                        <div class="invalid-feedback"
                            ng-show="SIGNUP.formUserSignup.nombre.$error.pattern && SIGNUP.formUserSignup.nombre.$touched">
                            Escribe
                            solo letras</div>
                    </div> -->
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                <button class="btn btn-primary" id="btn-guardar" type="button" (click)="saveData();"
                    [disabled]="!formSave.valid">Enviar</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="confirmationModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header header-success-save">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <h5>{{res}}</h5>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" id="btn-guardar" data-bs-dismiss="modal" type="button">Aceptar</button>
            </div>
        </div>
    </div>
</div>
<!-- Full screen modal -->
<!-- Modal -->
<div class="modal fade" id="modalCotiza" tabindex="-1" aria-labelledby="modalRegistrer" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel">Habla con un experto</h2>
                <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            <div class="modal-body">
                <iframe src="https://meetings.hubspot.com/luis-pedroza?embed=true" frameborder="0" width="100%" height="700px"></iframe>
            </div>
        </div>
    </div>
</div>
<ngx-spinner type="ball-scale-multiple"><p style="color: #fff;">Descargando...</p></ngx-spinner>
