import { Component, OnInit } from '@angular/core';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faLinkedin, faTwitterSquare, faInstagramSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  faSquare = faSquare;
  faFacebook = faFacebookSquare;
  faLinkedin = faLinkedin
  faTwitter = faTwitterSquare;
  faInstagramSquare = faInstagramSquare;
  faYoutubeSquare = faYoutubeSquare;

  constructor() { }

  ngOnInit(): void {
  }

}
