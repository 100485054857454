import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from '@services/home.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxCaptureService } from 'ngx-capture';
import { NgxSpinnerService } from "ngx-spinner";
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

declare var $ : any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  
  formSave: FormGroup;

  data: any = {};
  totalColaboradores: any = 0;
  totalColaboradoresCopy: any = 0;
  categoryOne: any = {};
  categoryTwo: any = {};
  categoryThree: any = {};
  categoryFour: any = {};
  categoryFive: any = {};
  report: any = 0;
  month: any = 0;
  average: any = 0;
  discountRate: any = 0;
  totalPercent: any = 1;
  totalPersonal: any = 1000;
  relationReportMonth: any = 0;
  productivityLoss: number = 0;
  monthlyProductivityIncrease:any = 0;
  oneYearPV:any = 0;
  threeYearsPV:any = 0;
  fiveYearsPV:any = 0;
  pwc:any = 0;
  cptr:any = 0;
  def1:any = 0;
  def2:any = 0;
  def3:any = 0;
  def4:any = 0;
  def5:any = 0;
  def6:any = 0;
  chart:any = {};
  mail:any = '';
  res:any = {};
  id:any = '';
  blob:any;
  @ViewChild('screen', { static: true }) screen: any;
  @ViewChild('screenBubble', { static: true }) screenBubble: any;

  public config = {
    displayModeBar: false, // this is the line that hides the bar.
  };

  public bubbleChartOptions = {
    layout: {
      padding: {
        bottom: 25
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        display: true,
        min: 0,
        max: 4000,
        ticks: {
          stepSize: 1000,
        },
        gridLines: {
          zeroLineColor: 'red',
          zeroLineWidth: 5,
        }
      },
      y: {
        gridLines: {
          zeroLineColor: 'red'
        },
        grid: {
          display: false
        },
        display: true,
        min: 0,
        max: 250000,
        ticks: {
          stepSize: 50000
        }
      },
    },
    plugins: {
      datalabels: {
        anchor: function(context) {
          var value = context.dataset.data[context.dataIndex];
          return value.r < 50 ? 'end' : 'center';
        },
        align: function(context) {
          var value = context.dataset.data[context.dataIndex];
          return value.r < 50 ? 'end' : 'center';
        },
        color: function(context) {
          var value = context.dataset.data[context.dataIndex];
          return value.r < 50 ? context.dataset.backgroundColor : 'white';
        },
        font: {
          weight: 'bold'
        },
        formatter: function(value) {
          return value.v;
        },
        offset: 2,
        padding: 0
      }
   }
  };
  public bubbleChartType = 'bubble';
  public bubbleChartLegend = true;

  public bubbleChartData = {
    labels: [],
    datasets: [ {
      data: [],
      label: '',
      backgroundColor: [
        '#35748e',
        '#56a54a',
        '#a2d564',
        '#d7954d',
        '#e1392e'
      ],
      //borderColor: 'blue',
      //hoverBackgroundColor: 'purple',
      //hoverBorderColor: 'red',
    } ]
  };

  public barChartPlugins = [
    DataLabelsPlugin
  ];
  showBubbleChart = false;
  
  constructor(private homeService: HomeService,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private captureService:NgxCaptureService,
              private spinner: NgxSpinnerService) {
    this.formSave = this.fb.group({
      'mail': ['', Validators.compose([
                Validators.required,
                Validators.pattern('[a-z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$')
              ])],
      'nombre': ['', Validators.compose([
        Validators.required
      ])],
      'telefono': ['', Validators.compose([])],
      'empresa': ['', Validators.compose([])]
    })              
  }
  
  ngOnInit(): void {
    console.log('mail', this.formSave.controls['mail']);
    
    this.route.queryParams
      .subscribe(params => {
        this.id = params.id ? params.id : '';
      }
    );
    
    this.homeService.getData(this.id)
      .subscribe(res => {
        this.data = res.data;
        this.totalColaboradores = this.data.totalColaboradores;
        this.totalColaboradoresCopy =  this.totalColaboradores;
        this.categoryOne = this.data.distIngresoSueldo.find(el => el.id == 1);
        this.categoryTwo = this.data.distIngresoSueldo.find(el => el.id == 2);
        this.categoryThree = this.data.distIngresoSueldo.find(el => el.id == 3);
        this.categoryFour = this.data.distIngresoSueldo.find(el => el.id == 4);
        this.categoryFive = this.data.distIngresoSueldo.find(el => el.id == 5);
        this.report = this.data.horas.reporte;
        this.month = this.data.horas.mes;
        this.average = this.data.horas.promedio;
        this.discountRate = this.data.tasaDescuento;
        this.relationReportMonth = (this.report / this.month) * 100;
        this.def1 = this.data.detalle.find(el => el.id == 1);
        this.def2 = this.data.detalle.find(el => el.id == 2);
        this.def3 = this.data.detalle.find(el => el.id == 3);
        this.def4 = this.data.detalle.find(el => el.id == 4);
        this.def5 = this.data.detalle.find(el => el.id == 5);
        this.def6 = this.data.detalle.find(el => el.id == 6);
        this.setChart();
        this.getTotals();
      });
  }

  setChart(){
      this.chart = {
          data: [{
              type: 'funnel',
              y: ["+$150K", "$100-$150K", "$50-$100K", "$20-50K", "<$20K"],
              x: [this.categoryOne.porcentajeRango, this.categoryTwo.porcentajeRango, this.categoryThree.porcentajeRango, this.categoryFour.porcentajeRango, this.categoryFive.porcentajeRango], 
              textposition: ["outside", "outside", "inside", "inside", "inside"],
              textinfo: "value+percent previous+percent initial",
              texttemplate: '%{value:.2f}%',
              hoverinfo: 'y+x',
              hovertemplate: '%{x}% <br> Rango: %{y}<extra></extra>'
          }],
          layout: {
              responsive: true,
              colorway: ['#004155'],
              yaxis: {
                visible: true
              }
          },
          useResizeHandler: true
      };
  }

  setTotoalPersonal() {
    this.categoryOne.numeroRango = Math.round(this.totalColaboradores * (this.categoryOne.porcentajeRango / 100));
    this.categoryTwo.numeroRango = Math.round(this.totalColaboradores * (this.categoryTwo.porcentajeRango / 100));
    this.categoryThree.numeroRango = Math.round(this.totalColaboradores * (this.categoryThree.porcentajeRango / 100));
    this.categoryFour.numeroRango = Math.round(this.totalColaboradores * (this.categoryFour.porcentajeRango / 100));
    this.categoryFive.numeroRango = Math.round(this.totalColaboradores * (this.categoryFive.porcentajeRango / 100));
    this.setChart();
    this.getTotals();
  }

  getPersonalNumber(category) {
   if(typeof category.porcentajeRango == 'number'){
    category.numeroRango = Math.round((category.porcentajeRango / 100) * this.totalColaboradores);
    this.setChart();
    this.getTotals();
   }
  }

  getPercentNumber(category) {
    if(typeof category.numeroRango == 'number'){
     category.porcentajeRango = (category.numeroRango * 100) / this.totalColaboradores;
     this.setChart();
     this.getTotals();
    }
  }

  getTotals() {
    this.totalPersonal = this.categoryOne.numeroRango + this.categoryTwo.numeroRango + this.categoryThree.numeroRango + this.categoryFour.numeroRango + this.categoryFive.numeroRango;
    this.totalPercent = (this.categoryOne.porcentajeRango / 100) + (this.categoryTwo.porcentajeRango / 100) + (this.categoryThree.porcentajeRango / 100) + (this.categoryFour.porcentajeRango / 100) + (this.categoryFive.porcentajeRango / 100);
    if (this.totalPersonal == this.totalColaboradores) {
      this.calculate();
    } else {
      console.log('Mensaje de colaboradores de mas o de menos');
    }
  }

  calculate() {
    this.productivityLoss = ((this.categoryOne.numeroRango * this.categoryOne.sueldo) + (this.categoryTwo.numeroRango * this.categoryTwo.sueldo)
      + (this.categoryThree.numeroRango * this.categoryThree.sueldo) + (this.categoryFour.numeroRango * this.categoryFour.sueldo)
      + (this.categoryFive.numeroRango * this.categoryFive.sueldo)) * (this.relationReportMonth / 100);

    this.categoryOne.productivityIncrease = this.categoryOne.porcentajeHora*(this.average/100);
    this.categoryTwo.productivityIncrease = this.categoryTwo.porcentajeHora*(this.average/100);
    this.categoryThree.productivityIncrease = this.categoryThree.porcentajeHora*(this.average/100);
    this.categoryFour.productivityIncrease = this.categoryFour.porcentajeHora*(this.average/100);
    this.categoryFive.productivityIncrease = this.categoryFive.porcentajeHora*(this.average/100);

    this.categoryOne.percentIncrease = this.categoryOne.productivityIncrease/(this.month/100);
    this.categoryTwo.percentIncrease = this.categoryTwo.productivityIncrease/(this.month/100);
    this.categoryThree.percentIncrease = this.categoryThree.productivityIncrease/(this.month/100);
    this.categoryFour.percentIncrease = this.categoryFour.productivityIncrease/(this.month/100);
    this.categoryFive.percentIncrease = this.categoryFive.productivityIncrease/(this.month/100);

    this.categoryOne.totalIncrease = (this.categoryOne.numeroRango*this.categoryOne.sueldo*this.categoryOne.productivityIncrease)/this.month;
    this.categoryTwo.totalIncrease = (this.categoryTwo.numeroRango*this.categoryTwo.sueldo*this.categoryTwo.productivityIncrease)/this.month;
    this.categoryThree.totalIncrease = (this.categoryThree.numeroRango*this.categoryThree.sueldo*this.categoryThree.productivityIncrease)/this.month;
    this.categoryFour.totalIncrease = (this.categoryFour.numeroRango*this.categoryFour.sueldo*this.categoryFour.productivityIncrease)/this.month;
    this.categoryFive.totalIncrease = (this.categoryFive.numeroRango*this.categoryFive.sueldo*this.categoryFive.productivityIncrease)/this.month;
    this.monthlyProductivityIncrease = ((this.categoryOne.numeroRango*this.categoryOne.sueldo*this.categoryOne.percentIncrease) +
                                        (this.categoryTwo.numeroRango*this.categoryTwo.sueldo*this.categoryTwo.percentIncrease) +
                                        (this.categoryThree.numeroRango*this.categoryThree.sueldo*this.categoryThree.percentIncrease) +
                                        (this.categoryFour.numeroRango*this.categoryFour.sueldo*this.categoryFour.percentIncrease) +
                                        (this.categoryFive.numeroRango*this.categoryFive.sueldo*this.categoryFive.percentIncrease))/100;

    this.oneYearPV = (((1 - Math.pow(1 + ((this.discountRate/100)/12), 12)) / ((this.discountRate/100)/12)) * -this.monthlyProductivityIncrease * (1 + ((this.discountRate/100)/12) * 0) - 0) / Math.pow(1 + ((this.discountRate/100)/12), 12);
    this.threeYearsPV = (((1 - Math.pow(1 + ((this.discountRate/100)/12), 36)) / ((this.discountRate/100)/12)) * -this.monthlyProductivityIncrease * (1 + ((this.discountRate/100)/12) * 0) - 0) / Math.pow(1 + ((this.discountRate/100)/12), 36);
    this.fiveYearsPV = (((1 - Math.pow(1 + ((this.discountRate/100)/12), 60)) / ((this.discountRate/100)/12)) * -this.monthlyProductivityIncrease * (1 + ((this.discountRate/100)/12) * 0) - 0) / Math.pow(1 + ((this.discountRate/100)/12), 60);
    this.pwc = Math.round(this.totalColaboradores*(this.data.pwc.otraEmpresa/100)*(this.data.pwc.rbf/100));
    this.cptr = Math.round(this.totalColaboradores*(this.data.ceridian.otroTrabajo/100))
    this.def1.total = Math.round(this.totalColaboradores*(this.def1.porcentaje/100));
    this.def2.total = Math.round(this.totalColaboradores*(this.def2.porcentaje/100));
    this.def3.total = Math.round(this.totalColaboradores*(this.def3.porcentaje/100));
    this.def4.total = Math.round(this.totalColaboradores*(this.def4.porcentaje/100));
    this.def5.total = Math.round(this.totalColaboradores*(this.def5.porcentaje/100));
    this.def6.total = Math.round(this.totalColaboradores*(this.def6.porcentaje/100));    
  }

  saveData(){
    $('#saveModal').modal('hide');
    this.spinner.show();
    setTimeout(() => {
      this.bubbleChartData.datasets[0].data[0] = { x: 1000, y: 15000, r: this.categoryFive.porcentajeRango, v: 'Grupo 5' };
      this.bubbleChartData.datasets[0].data[1] = { x: 2000, y: 35000, r: this.categoryFour.porcentajeRango, v: 'Grupo 4' };
      this.bubbleChartData.datasets[0].data[2] = { x: 2500, y: 75000, r: this.categoryThree.porcentajeRango, v: 'Grupo 3' };
      this.bubbleChartData.datasets[0].data[3] = { x: 3000, y: 125000, r: this.categoryTwo.porcentajeRango, v: 'Grupo 2' };
      this.bubbleChartData.datasets[0].data[4] = { x: 3500, y: 200000, r: this.categoryOne.porcentajeRango, v: 'Grupo 1' };
      this.showBubbleChart = true;
    }, 2000);
    setTimeout(() => {
      this.captureService.getImage(this.screen.nativeElement, true).subscribe( img => {
        this.data.chartImg = img;
        this.getBubbleChart();
      }, error => {
        console.log('error', error);
        this.spinner.hide();
      });
    }, 2000);
  }

  getBubbleChart(){
    setTimeout(() => {
      this.captureService.getImage(this.screenBubble.nativeElement, true).subscribe( img => {
        this.data.bubbleChartImg = img;
        console.log('this.data.bubbleChartImg', this.data.bubbleChartImg);
        this.postData();
        this.showBubbleChart = false;
      }, error => {
        console.log('error', error);
        this.spinner.hide();
      });
    }, 5000);
  }

  postData(){
    if(this.totalColaboradores <= 2){
      this.data.rangoEmpresas[0].descripcion = 'Perteneces al 72.3665% de las empresas más grandes en México';
    } else if(this.totalColaboradores > 2 && this.totalColaboradores <= 5){
      this.data.rangoEmpresas[1].descripcion = 'Perteneces al 34.1535% de las empresas más grandes en México';
    } else if(this.totalColaboradores > 5 && this.totalColaboradores <= 50){
      this.data.rangoEmpresas[2].descripcion = 'Perteneces al 5.3671% de las empresas más grandes en México';
    } else if(this.totalColaboradores > 50 && this.totalColaboradores <= 250){
      this.data.rangoEmpresas[3].descripcion = 'Perteneces al 1.1095% de las empresas más grandes en México';
    } else if(this.totalColaboradores > 250 && this.totalColaboradores <= 500){
      this.data.rangoEmpresas[4].descripcion = 'Perteneces al 0.516% de las empresas más grandes en México';
    } else if(this.totalColaboradores > 500 && this.totalColaboradores < 1000){
      this.data.rangoEmpresas[5].descripcion = 'Perteneces al 0.2157% de las empresas más grandes en México';
    } else if(this.totalColaboradores >= 1000){
      this.data.rangoEmpresas[6].descripcion = 'Perteneces al 0.2157% de las empresas más grandes en México por número de colaboradores';
    }
    this.data.correo =  this.formSave.controls['mail'].value;
    this.data.nombre =  this.formSave.controls['nombre'].value;
    this.data.telefono =  this.formSave.controls['telefono'].value;
    this.data.empresa =  this.formSave.controls['empresa'].value;
    this.data.totalColaboradores = this.totalColaboradores;
    this.data.distIngresoSueldo = [];
    this.data.distIngresoSueldo.push(this.categoryOne);
    this.data.distIngresoSueldo.push(this.categoryTwo);
    this.data.distIngresoSueldo.push(this.categoryThree);
    this.data.distIngresoSueldo.push(this.categoryFour);
    this.data.distIngresoSueldo.push(this.categoryFive);
    this.data.ceridian.total = this.cptr;
    this.data.pwc.total = this.pwc;
    this.data.detalle = [];
    this.data.detalle.push(this.def1);
    this.data.detalle.push(this.def2);
    this.data.detalle.push(this.def3);
    this.data.detalle.push(this.def4);
    this.data.detalle.push(this.def5);
    this.data.detalle.push(this.def6);
    this.data.horas.promedio = this.average;
    this.data.totales = {
      perdidaProdMes : this.productivityLoss,
      horaPreocupadoSemana : 14,
      horaPreocupadoPromedio : this.average,
      incrementoProdMes : this.monthlyProductivityIncrease,
      incrementoProd1 : this.oneYearPV,
      incrementoProd3 : this.threeYearsPV,
      incrementoProd5 : this.fiveYearsPV,
    }
    console.log('this.data', this.data);
    this.homeService.postData(this.data)
      .subscribe(res => {
        console.log('res', typeof res);
        this.blob = new Blob([res], {type: 'application/pdf'});
        var downloadURL = window.URL.createObjectURL(res);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = "Invested - Productividad e Impacto del Bienestar Financiero.pdf";
        link.click();
        this.spinner.hide();
        this.formSave.reset({
          mail: '',
          nombre: "",
          telefono: "",
          empresa: ""
        });
      }, err => {
        this.spinner.hide();
        console.log('err', err);
      });
  }

  onFocus(){
    this.totalColaboradores = '';
  }

  blur(){
    if( this.totalColaboradores == '' ){
      this.totalColaboradores = this.totalColaboradoresCopy;
    } else {
      this.totalColaboradoresCopy = this.totalColaboradores;
    }
  }
}
