<div class="container-fluid">
    <div class="row footer">
        <div class="col-md-12 mt-2">
            <p class="text-white text-center mt-4" style="font-size: 18px;">© 2022 Powered by Invested</p>
            <div class="text-center mt-2">
                <img class="mx-auto img-logo-modals-goals mt-0" src="assets/img/logo_invested_blanco.png" alt="Logo">
                <p class="fs-3 my-2"><a class="link-light" id="link-invested-page" href="https://invested.mx/" target="_blank" rel="noopener noreferrer">www.invested.mx</a></p>
                <p class="mt-4">
                    <a class="mx-4" href="https://www.facebook.com/InvestedMx" target="_blank" rel="noopener noreferrer">
                        <span class="fa-layers">
                            <fa-icon class="fs-1" id="icon-faSquare" [icon]="faSquare"></fa-icon>
                            <fa-icon class="fs-1" id="icon-facebook" [icon]="faFacebook"></fa-icon>
                        </span>
                    </a>
                    <a class="mx-4" href="https://www.linkedin.com/company/invested-mx" target="_blank" rel="noopener noreferrer">
                        <span class="fa-layers">
                            <fa-icon class="fs-1" id="icon-faSquare" [icon]="faSquare"></fa-icon>
                            <fa-icon class="fs-1" id="icon-linkedin" [icon]="faLinkedin"></fa-icon>
                        </span>
                    </a>
                    <a class="mx-4" href="https://twitter.com/InvestedMx" target="_blank" rel="noopener noreferrer">
                        <span class="fa-layers">
                            <fa-icon class="fs-1" id="icon-faSquare" [icon]="faSquare"></fa-icon>
                            <fa-icon class="fs-1" id="icon-twitter" [icon]="faTwitter"></fa-icon>
                        </span>
                    </a>
                    <a class="mx-4" href="https://www.instagram.com/invested_mx" target="_blank" rel="noopener noreferrer">
                    <span class="fa-layers">
                        <fa-icon class="fs-1" id="icon-faSquare" [icon]="faSquare"></fa-icon>
                        <fa-icon class="fs-1" id="icon-instangram" [icon]="faInstagramSquare"></fa-icon>
                    </span>
                    </a>
                    <a class="mx-4" href="https://www.youtube.com/c/InvestedMx" target="_blank" rel="noopener noreferrer">
                        <span class="fa-layers text-center">
                            <fa-icon class="fs-1" id="icon-faSquare" [icon]="faSquare"></fa-icon>
                            <fa-icon class="fs-1" id="icon-youtube" [icon]="faYoutubeSquare"></fa-icon>
                        </span>
                    </a>
                </p>
            </div>
        </div>
    </div>
</div>